<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="transactionData === undefined"
    >
      <h4 class="alert-heading">
        {{ t('Error fetching sale data') }}
      </h4>
      <div class="alert-body">
        {{ t('No sale found with this sale id. Or this sale deleted. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-transaction-list'}"
        >
          {{ t('Sale List') }}
        </b-link>
        {{ t('for other transaction.') }}
      </div>
    </b-alert>

    <b-row
      v-if="transactionData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="10"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    swm
                  </h3>
                </div>
                <p class="card-text mb-25">
                  company name, address, itn
                </p>
                <p class="card-text mb-25">
                  bank account, etc
                </p>
                <p class="card-text mb-0">
                  +998946393966
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ t('Sale') }}
                  <span class="invoice-number">#{{ transactionData.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Order date') }}:
                  </p>
                  <p class="invoice-date">
                    {{ transactionData.date }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    {{ t('Status') }}:
                  </p>
                  <b-badge
                    till
                    :variant="`light-${resolveVariant(transactionData.status)}`"
                    class="invoice-date"
                  >
                    {{ transactionData.status }}
                  </b-badge>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="transactionData.saleClient"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->

              <b-col
                cols="12"
                xl="3"
                class="p-0 pb-2"
              >
                <h6 class="mb-1">
                  {{ t('Warehouse') }}:
                </h6>
                <h6 class="mb-25">
                  {{ `${transactionData.saleWarehouse.name} ${transactionData.saleWarehouse.type}` }}
                </h6>
                <!-- <p class="card-text mb-25">
                  {{ transactionData.Client.phone }}
                </p> -->
                <p class="card-text mb-25">
                  {{ transactionData.saleWarehouse.address }}, {{ transactionData.saleWarehouse.city }}
                </p>
                <p class="card-text mb-50">
                  {{ transactionData.saleWarehouse.comments }}
                </p>
                <!-- <p class="card-text mb-0">
                  {{ transactionData.Client.email }}
                </p> -->
              </b-col>

              <b-col
                cols="12"
                xl="3"
                class="p-0 pb-2"
              >
                <h6 class="mb-1">
                  {{ t('Customer') }}:
                </h6>
                <h6 class="mb-25">
                  {{ `${transactionData.saleClient.firstname} ${transactionData.saleClient.surname}` }}
                </h6>
                <!-- <p class="card-text mb-25">
                  {{ transactionData.saleClient.phone }}
                </p> -->
                <p class="card-text mb-25">
                  {{ transactionData.saleClient.address }}, {{ transactionData.saleClient.city }}
                </p>
                <p class="card-text mb-25">
                  {{ transactionData.saleClient.phone }}
                </p>
                <p class="card-text mb-50">
                  {{ transactionData.saleClient.email }}
                </p>
              </b-col>

              <b-col
                cols="12"
                xl="3"
                class="pb-2"
              >
                <h6 class="mb-1">
                  {{ t('Car') }}:
                </h6>
                <h6
                  v-for="car in transactionData.saleCars"
                  :key="car.id"
                  class="p-0 mb-50"
                >
                  {{ `${car.number} ${car.name}` }}
                </h6>
                <!-- <p class="card-text mb-25">
                  {{ transactionData.Client.phone }}
                </p> -->
                <!-- <p class="card-text mb-25">
                  {{ transactionData.Warehouse.address }}, {{ transactionData.Warehouse.city }}
                </p>
                <p class="card-text mb-25">
                  {{ transactionData.Warehouse.comments }}
                </p> -->
                <!-- <p class="card-text mb-0">
                  {{ transactionData.Client.email }}
                </p> -->
              </b-col>

              <b-col
                cols="12"
                xl="3"
                class="p-0"
              >
                <h6 class="mb-1">
                  {{ t('Responsible') }}:
                </h6>

                <h6 class="mb-25">
                  {{ `${transactionData.saleResponsible.firstName} ${transactionData.saleResponsible.lastName}` }}
                </h6>
                <!-- <p class="card-text mb-25">
                  {{ transactionData.Client.phone }}
                </p> -->
                <p class="card-text mb-25">
                  {{ transactionData.saleResponsible.email }}
                </p>

                <!-- <h6
                  v-for="car in transactionData.Cars"
                  :key="car.id"
                  class="mb-25"
                >
                  {{ `${car.number} ${car.name}` }}
                </h6> -->
                <!-- <p class="card-text mb-25">
                  {{ transactionData.Client.phone }}
                </p> -->
                <!-- <p class="card-text mb-25">
                  {{ transactionData.Warehouse.address }}, {{ transactionData.Warehouse.city }}
                </p>
                <p class="card-text mb-25">
                  {{ transactionData.Warehouse.comments }}
                </p> -->
                <!-- <p class="card-text mb-0">
                  {{ transactionData.Client.email }}
                </p> -->
              </b-col>

              <!-- Col: Payment Details
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold">{{ paymentDetails.totalDue }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Bank name:
                        </td>
                        <td>{{ paymentDetails.bankName }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Country:
                        </td>
                        <td>{{ paymentDetails.country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>{{ paymentDetails.iban }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>{{ paymentDetails.swiftCode }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col> -->
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="transactionData.saleProducts"
            :fields="[{ key: 'name', label: t('Product') }, {key: 'cost', label: t('cost') }, {key: 'quantity', label: t('quantity') }, {key: 'weight', label: t('weight') }, {key: 'price', label: t('price') }]"
          >
            <template #cell(name)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.name }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.description }}
              </b-card-text>
            </template>

            <template #cell(cost)="data">
              <b-card-text class="mb-25">
                {{ data.item.TransactionProducts.cost }}
              </b-card-text>
            </template>

            <template #cell(quantity)="data">
              <b-card-text class="mb-25">
                {{ data.item.TransactionProducts.quantity }}
              </b-card-text>
            </template>

            <template #cell(weight)="data">
              <b-card-text class="mb-25">
                {{ data.item.TransactionProducts.weight }}
              </b-card-text>
            </template>

            <template #cell(price)="data">
              <b-card-text class="mb-25">
                {{ data.item.TransactionProducts.weight*data.item.TransactionProducts.cost }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Transaction Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <!-- <b-card-text class="mb-0">
                  <span class="font-weight-bold">Transactionperson:</span>
                  <span class="ml-75">Alfie Solomons</span>
                </b-card-text> -->
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ t('Total price') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ totalPrice }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ t('Note') }}: </span>
            <span>{{ transactionData.comments }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="2"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            Send Invoice
          </b-button> -->

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'apps-transaction-list'}"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mx-auto"
              size="18"
            />
            {{ t('Back to list') }}
          </b-button>

          <b-button
            v-if="transactionData.status === 'Draft'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="submitOnProcess.save"
            @click.prevent="onSubmitSave"
          >
            <b-spinner
              v-if="submitOnProcess"
              small
              type="grow"
            />
            {{ t('Sell') }}
          </b-button>

          <!-- Button: DOwnload
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printPurchase"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mx-auto"
              size="18"
            />
            {{ t('Print') }}
          </b-button>

          <!-- Button: Edit -->
          <b-button
            v-if="transactionData.status === 'Draft'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            {{ t('Edit') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            block
            :disabled="isDeleting"
            @click.prevent="promptDialog"
          >
            {{ t('Delete') }}
          </b-button>

          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            block
            :disabled="submitOnProcess.save || submitOnProcess.saveandsell || submitOnProcess.todraft"
            :to="{ name: 'apps-transaction-list'}"
          >
            {{ t('Cancel') }}
          </b-button> -->

          <!-- Button: Cancel -->
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row
      v-else-if="transactionData !== undefined"
      class="invoice-preview"
    >

      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <div
            class="text-center text-primary my-2"
          >
            <b-spinner
              class="align-middle mr-1"
            />
            <strong>{{ t('Загрузка') }}...</strong>
          </div>
        </b-card>
      </b-col>
    </b-row> -->
    <div
      v-else-if="transactionData !== undefined"
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>{{ t('Загрузка') }}...</strong>
    </div>
  </section>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BSpinner, BBadge,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import transactionStoreModule from '../transactionStoreModule'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BSpinner,
    BBadge,

    Logo,
  },
  methods: {
    promptDialog() {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            this.deleteSale()
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    const { t } = useI18nUtils()
    const isDeleting = ref(false)

    const transactionData = ref(null)

    // Invoice Description
    // ? Your real data will contain this information
    const purchaseProducts = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const TRANSACTION_APP_STORE_MODULE_NAME = 'app-transaction'

    // Register module
    if (!store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.registerModule(TRANSACTION_APP_STORE_MODULE_NAME, transactionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.unregisterModule(TRANSACTION_APP_STORE_MODULE_NAME)
    })

    const fetchSale = () => store.dispatch('app-transaction/fetchSale', { id: router.currentRoute.params.id })
      .then(response => {
        if (response.data === null) {
          transactionData.value = undefined
        } else {
          transactionData.value = response.data
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          transactionData.value = undefined
        }
      })

    fetchSale()

    const deleteSale = () => {
      isDeleting.value = true
      store.dispatch('app-transaction/deleteSale', { id: transactionData.value.id })
        .then(response => {
          if (response.data.code === 1) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Успешно',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Вы успешно нажали на кнопку сохранить.',
              },
            })
            // resetForm()
            router.push({ name: 'apps-transaction-list' })
          } else {
            // transactionData.value = response.data
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            // transactionData.value = undefined
          }
        })
        .finally(() => {
          isDeleting.value = false
        })
    }

    const totalPrice = computed(() => transactionData.value.saleProducts.reduce((total, item) => total + item.TransactionProducts.weight * item.TransactionProducts.cost, 0))

    const resolveVariant = status => {
      if (status === 'New') return 'primary'
      // if (status === 'Closed') return 'danger'
      // if (status === 'Downloaded') return 'secondary'
      if (status === 'Draft') return 'warning'
      // if (status === 'Sent') return 'info'
      if (status === 'Closed') return 'success'
      return 'primary'
    }

    const printPurchase = () => {
      window.print()
    }

    const submitOnProcess = ref(false)
    const onSubmitSave = () => {
      submitOnProcess.value = true
      // console.log('transactionData.value.file', transactionData.value.file)
      const payload = JSON.parse(JSON.stringify(transactionData.value))
      store.dispatch('app-transaction/updateSale', {
        id: payload.id,
        date: payload.date,
        supplier: payload.client_id,
        responsible: payload.resp_id,
        warehouse: payload.wm_id,
        car: payload.saleCars.map(car => car.id).join(),
        items: payload.saleProducts.map(product => ({
          product: product.id,
          cost: parseFloat(product.TransactionProducts.cost),
          quantity: parseFloat(product.TransactionProducts.quantity),
          weight: parseFloat(product.TransactionProducts.weight),
          description: product.description,
          subItems: product.Transactionubitems.map(subitem => ({ weight: parseFloat(subitem.weight) })),
        })),
        status: 'Closed',
        note: payload.comments,
        actiontype: 'save',
      })
        .then(response => {
          console.log(response)
          // const { records } = response.data
          // if (records) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          // router.push({ name: 'apps-transaction-preview', params: { id: response.data.sale_id } })
          fetchSale()
          // }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding new purchase',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        })
        .finally(() => {
          submitOnProcess.value = false
        })
    }

    return {
      transactionData,
      purchaseProducts,
      printPurchase,
      t,
      totalPrice,
      resolveVariant,
      deleteSale,
      isDeleting,
      onSubmitSave,
      submitOnProcess,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
